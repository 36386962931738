
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";

export default defineComponent({
  components: {
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    var printColumns = [{ field: "Name" }];

    const gridOptions: GridOptions = {
      id: 'supportable-facility',
      title: '提供設施',
      mode: "inline",
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "提供設施清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "提供設施清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          editRender: { name: '$input' }
        },
      ],
      promises: {
        query: model ? (params) => model.dispatch("supportableFacility/query", params) : undefined,
        queryAll: model ? () => model.dispatch("supportableFacility/query") : undefined,
        save: model ? (params) => model.dispatch("supportableFacility/save", params) : undefined,
      },
      modalConfig: { width: 600, height: 510 },
    };

    return {
      grid,
      gridOptions,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
  },
});
